import React from 'react';
import { Helmet } from 'react-helmet'; // Pour le SEO
import './About.scss'; // SCSS pour le style

const About = () => {
  return (
    <div className="about-container">
      <Helmet>
        <title>À propos de notre service de réparation</title>
        <meta name="description" content="En savoir plus sur notre service de réparation rapide et fiable." />
      </Helmet>

      <div className="about-wrapper">
        <div className="about-section">
          <h1 className="about-title">À propos de notre service de réparation</h1>
          <p className="about-description">
            Chez <strong>I repair it</strong>, nous comprenons à quel point votre téléphone est essentiel à votre quotidien.
            C’est pourquoi nous offrons un service rapide, fiable et écologique pour réparer vos appareils.
          </p>
          <p className="about-tagline">
            Faites-nous confiance pour redonner vie à vos téléphones !
          </p>
        </div>

        {/* Section des réseaux sociaux */}
        <div className="social-media-section">
          <h2 className="social-media-title">Suivez-nous sur les réseaux sociaux</h2>
          <div className="social-links">
            <a href="https://facebook.com" className="social-link facebook" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-facebook-f"></i> Facebook
            </a>
            <a href="https://instagram.com" className="social-link instagram" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-instagram"></i> Instagram
            </a>
            <a href="https://twitter.com" className="social-link twitter" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-twitter"></i> Twitter
            </a>
            <a href="https://youtube.com" className="social-link youtube" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-youtube"></i> YouTube
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
