import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRepair } from './RepairContext';
import { useAuth } from '../../components/AuthContext';
import './ConfirmationPage.scss';

const ConfirmationPage = () => {
    const navigate = useNavigate();
    const { repairData } = useRepair();
    const { user } = useAuth();
    const [error, setError] = useState(null);
    const [repairDetails, setRepairDetails] = useState(null);

    useEffect(() => {
        if (!repairData?.repairId) {
            setError('Aucune réparation trouvée.');
            return;
        }

        fetch(`https://shoponlignebackend-production.up.railway.app/repairs/${repairData.repairId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${user?.token}`,
            },
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Erreur lors de la récupération des détails de la réparation.');
                }
                return response.json();
            })
            .then((data) => setRepairDetails(data))
            .catch((err) => setError('Impossible de récupérer les détails de la réparation.'));
    }, [repairData, user]);

    return (
        <div className="container mt-5 confirmation-container">
        <div className="text-center">
            <h2>Votre commande est confirmée ! 🎉</h2>
            <p className="text-success">
                Merci pour votre confiance, {user?.name || 'cher client'} ! <br />
                Votre réparation est en cours de traitement.
            </p>
        </div>
    
        <div className="details">
            <h4 className="title text-center"> Détails de Votre Réparation </h4>
    
            {error ? (
                <p className="text-danger alert-message">Oops! Il y a eu une erreur. {error}</p>
            ) : repairDetails ? (
                <div className="repair-info">
                    <div className="info-item">
                        <span className="info-title">Référence de votre Réparation :</span>
                        <span className="info-value">{repairDetails.id}</span>
                    </div>
                    <div className="info-item">
                        <span className="info-title">Date de Commande :</span>
                        <span className="info-value">{new Date(repairDetails.creationDate).toLocaleDateString()}</span>
                    </div>
                    <div className="info-item">
                        <span className="info-title">Votre Smartphone :</span>
                        <span className="info-value">{}</span>
                    </div>
                    <div className="info-item">
                        <span className="info-title">Services de Réparation commandés :</span>
                        <span className="info-value">{repairDetails.services}</span>
                    </div>
                    <div className="info-item">
                        <span className="info-title">Coût :</span>
                        <span className="info-value">{repairDetails.cost ? `${repairDetails.cost} €` : 'À déterminer'}</span>
                    </div>
                </div>
            ) : (
                <p className="loading-message">🔄 Chargement des détails de la réparation...</p>
            )}
        </div>
    
        {/* Espace entre les détails et le bouton */}
        <div className="text-center mt-4">
            <button onClick={() => navigate('/')} className="btn btn-primary btn-home">
                Retour à l'Accueil
            </button>
        </div>
    </div>
    
    );
};

export default ConfirmationPage;
