import React from 'react';
import { Container, Tab, Tabs, Card, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useRepair } from './RepairContext';
import './ModeDeReparation.scss';

const ModeDeReparation = () => {
  const navigate = useNavigate();
  const { updateRepairData } = useRepair();

  const handleModeSelection = (mode) => {
    updateRepairData('repairType', mode);
    if (mode === 'in-shop') {
      navigate('/mode-in-shop');
    } else {
      navigate('/mode-en-ligne');
    }
  };

  return (
    <Container className="mode-reparation-container mt-5 px-3">
      <h1 className="text-center mb-4 fw-bold">Choisissez votre mode de réparation</h1>

      <Tabs
        defaultActiveKey="online"
        id="mode-de-reparation-tabs"
        className="mb-3 nav-justified"
        variant="pills"
      >
        {/* Onglet En Ligne */}
        <Tab eventKey="online" title="En Ligne">
          <Card className="shadow-lg border-0 text-center hover-effect mt-3">
            <Card.Body>
              <Card.Title className="display-6 text-primary">Réparation En Ligne</Card.Title>
              <Card.Text className="text-muted my-3">
                Envoyez-nous votre appareil et nous nous chargeons du reste. Suivez votre
                réparation en temps réel.
              </Card.Text>
              <Button
                variant="primary"
                className="btn btn-lg w-100 btn-explore"
                onClick={() => handleModeSelection('online')}
              >
                Choisir En Ligne
              </Button>
            </Card.Body>
          </Card>
        </Tab>

        {/* Onglet En Boutique */}
        <Tab eventKey="in-shop" title="En Boutique">
          <Card className="shadow-lg border-0 text-center hover-effect mt-3">
            <Card.Body>
              <Card.Title className="display-6 text-secondary">Réparation En Boutique</Card.Title>
              <Card.Text className="text-muted my-3">
                Profitez d'un service rapide et professionnel directement dans l'une de nos
                boutiques partenaires.
              </Card.Text>
              <Button variant="secondary" className="btn btn-lg w-100 btn-explore disabled">
                Bientôt Disponible
              </Button>
            </Card.Body>
          </Card>
        </Tab>
      </Tabs>
    </Container>
  );
};

export default ModeDeReparation;
