import React, { useEffect, useState } from 'react';
import { Container, Nav, Tab } from 'react-bootstrap';
import Inscription from './Inscription';
import Connexion from './Connexion';
import './AccessPage.scss';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../components/AuthContext';
import { Helmet } from 'react-helmet'; // Importer Helmet
import seoContent from '../../seo/seoContent'; // Importer le contenu SEO

const AccessPage = () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkScreenSize = () => setIsMobile(window.innerWidth <= 768);
    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);
    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/account');
    }
  }, [isAuthenticated, navigate]);

  if (isAuthenticated) {
    return null;
  }

  return (
    <Container
      className="access-page-container"
      style={{ height: isMobile ? 'auto' : '100vh' }}
    >
      {/* Ajouter Helmet pour la gestion dynamique du titre et de la description */}
      <Helmet>
        <title>{seoContent.accessPage.title}</title> {/* Titre spécifique à la page d'accès */}
        <meta name="description" content={seoContent.accessPage.description} />
      </Helmet>

      <Tab.Container defaultActiveKey="connexion" className="tab-container">
        <Nav variant="tabs" className={`justify-content-center mb-2 ${isMobile ? 'mobile-tabs' : 'desktop-tabs'}`}>
          <Nav.Item>
            <Nav.Link eventKey="connexion">Connexion</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="inscription">Inscription</Nav.Link>
          </Nav.Item>
        </Nav>
        <Tab.Content>
          <Tab.Pane eventKey="connexion">
            <div className="custom-card-container">
              <Connexion />
            </div>
          </Tab.Pane>
          <Tab.Pane eventKey="inscription">
            <div className="custom-card-container">
              <Inscription />
            </div>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </Container>
  );
};

export default AccessPage;
