import React, { useState } from 'react';
import { Container, Row, Col, Form, Button, Toast, ToastContainer } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet'; // Importer Helmet
import seoContent from '../seo/seoContent'; // Importer le contenu SEO

import './ContactPage.scss';

const ContactPage = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [showToast, setShowToast] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://shoponlignebackend-production.up.railway.app/contact', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setShowToast(true);
        setTimeout(() => {
          navigate('/'); // Rediriger vers la page d'accueil après un délai
        }, 3000);
      } else {
        alert('Une erreur est survenue. Veuillez réessayer.');
      }
    } catch (error) {
      console.error('Erreur lors de l\'envoi :', error);
    }
  };

  return (
    <div className="main-content">
      {/* Ajouter Helmet pour la gestion dynamique du titre et de la description */}
      <Helmet>
        <title>{seoContent.contact.title}</title>
        <meta name="description" content={seoContent.contact.description} />
      </Helmet>

      <Container className="mt-5">
        <section className="section text-center">
          <h1>Contactez-nous</h1>
        </section>
        <Row>
          <Col md={6} className="mx-auto">
            <h2>Formulaire de Contact</h2>
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formName">
                <Form.Label>Nom</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  placeholder="Entrez votre nom"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </Form.Group>

              <Form.Group controlId="formEmail" className="mt-3">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  placeholder="Entrez votre email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </Form.Group>

              <Form.Group controlId="formMessage" className="mt-3">
                <Form.Label>Message</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={4}
                  name="message"
                  placeholder="Écrivez votre message ici..."
                  value={formData.message}
                  onChange={handleChange}
                  required
                />
              </Form.Group>

              <Button type="submit" className="btn btn-contact">
  Envoyer
</Button>



            </Form>
          </Col>
        </Row>
      </Container>

      {/* Toast Container pour afficher le message de confirmation */}
      <ToastContainer position="absolute" className="toast-container">
        <Toast
          show={showToast}
          onClose={() => setShowToast(false)}
          delay={3000}
          autohide
          className="toast-message"
        >
          <Toast.Body>
            <strong>Merci !</strong> Votre message a bien été envoyé.
          </Toast.Body>
        </Toast>
      </ToastContainer>
    </div>
  );
};

export default ContactPage;
