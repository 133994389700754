import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import brandsData from './brands.json';
import { Container } from 'react-bootstrap';
import './BrandsList.scss'; // Importer les styles

// Fonction pour charger les images
const importAll = (r) => {
  let images = {};
  r.keys().forEach((item) => { images[item.replace('./', '')] = r(item); });
  return images;
};

// Charger toutes les images du dossier
const images = importAll(require.context('../../../src/assets/images', false, /\.(png|jpe?g|svg)$/));

const BrandsList = () => {
  const [searchTerm, setSearchTerm] = useState('');

  // Filtrer les marques en fonction du terme de recherche
  const filteredBrands = brandsData.filter(brand =>
    brand.brand.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      <Container className="mt-5">
        {/* Section en dehors du contenu principal */}
        <div className="section text-center">
          <h1 className="heading">Découvrez nos marques partenaires</h1>
          <p className="subheading">Sélectionnez la marque de votre téléphone pour voir nos services de réparation et d'achat.</p>
        </div>

        {/* Contenu principal (Main Content) */}
        {/* Section pour la liste des marques */}
        <div className="brand-list-section">
          {/* Champ de recherche pour filtrer les marques */}
          <input
            type="text"
            placeholder="Sélectionnez la marque de votre téléphone..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="form-control mb-3 search-bar"
          />

          {/* Liste des marques filtrées */}
          <div className="brands-list row justify-content-center">
            {filteredBrands.map((brand, index) => (
              <div key={brand.brand} className="brand-item col-2 col-md-2 col-sm-2 mb-3">
                <Link to={`/repair/${brand.brand.toLowerCase()}`} className="brand-link">
                  {images[brand.image] && (
                    <img 
                      src={images[brand.image]} 
                      alt={`Logo de la marque ${brand.brand}`} 
                      className="img-fluid brand-logo"
                      style={{ animationDelay: `${index * 0.1}s`, maxWidth: '100%', height: 'auto' }}
                    />
                  )}
                  <p className="brand-name">{brand.brand}</p>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </Container>
    </>
  );
};

export default BrandsList;
