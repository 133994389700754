import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import BrandsList from './pages/brands/BrandsList';
import './App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './css/bootstrap-custom.scss';
import PhoneList from './pages/phones/PhoneList';
import PhoneDetailPage from './pages/phones/PhoneDetailPage';
import RepairServices from './pages/repair/RepairServicesPage';
import ModeDeReparation from './pages/repair/ModeDeReparation';
import ModeEnLigne from './pages/repair/ModeEnLigne';
import ModeInShop from './pages/repair/ModeInShop';
import Inscription from './pages/user/Inscription';
import HomePage from './pages/Home/HomePage';
import ContactPage from './pages/ContactPage';
import 'leaflet/dist/leaflet.css';
import AccountPage from './pages/Custumer/AccountPage';
import AccountOverview from './pages/Custumer/AccountOverview';
import AccountDetails from './pages/Custumer/AccountDetails';
import Logout from './pages/Custumer/Logout';
import AccessPage from './pages/user/AccessPage';
import RepairHistory from './pages/Custumer/RepairHistory';
import CreateRepairPage from './pages/repair/CreateRepairPage';
import PaymentPage from './pages/PaymentPage';
import ConfirmationPage from './pages/repair/ConfirmationPage';
import Footer from './components/Footer';
import Header from './components/Header';
import { AuthProvider, useAuth } from './components/AuthContext';
import { RepairProvider } from './pages/repair/RepairContext';
import { ClientProvider } from './pages/repair/ClientContext';
import RepairServicesList from './pages/repair/RepairServicesList';
import About from './pages/About';

function ProtectedRoute({ children }) {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? children : <Navigate to="/acces" replace />;
}

function App() {
  return (
    <AuthProvider>
      <RepairProvider>
        <ClientProvider>
          <div className="App">
            <Header />
            <div className="container-fluid px-3 main-content">
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/repair/:brand" element={<PhoneList />} />
                <Route path="/phone-detail/:model" element={<PhoneDetailPage />} />
                <Route path="/repair-services" element={<RepairServices />} />
                <Route path="/mode-de-reparation" element={<ModeDeReparation />} />
                <Route path="/mode-en-ligne" element={<ModeEnLigne />} />
                <Route path="/mode-in-shop" element={<ModeInShop />} />
                <Route path="/inscription" element={<Inscription />} />
                <Route path="/repair" element={<BrandsList />} />
                <Route path="/contact" element={<ContactPage />} />
                <Route path="/acces" element={<AccessPage />} />
                <Route path="/payment" element={<PaymentPage />} />
                <Route path="/createrepairpage" element={<CreateRepairPage />} />
                <Route path="/confirmation" element={<ConfirmationPage />} />
                <Route path="/List-Repair-Services" element={<RepairServicesList />} />
                <Route path="/about" element={<About />} />
                <Route
                  path="/account"
                  element={
                    <ProtectedRoute>
                      <AccountPage />
                    </ProtectedRoute>
                  }
                >
                  <Route index element={<Navigate to="/account/account-overview" />} />
                  <Route path="account-overview" element={<AccountOverview />} />
                  <Route path="account-details" element={<AccountDetails />} />
                  <Route path="repair-history" element={<RepairHistory />} />
                  <Route path="logout" element={<Logout />} />
                </Route>
              </Routes>
            </div>
            <Footer />
          </div>
        </ClientProvider>
      </RepairProvider>
    </AuthProvider>
  );
}

export default App;
