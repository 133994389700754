import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../components/AuthContext';  // Importer le hook de contexte d'authentification

const Logout = () => {
  const navigate = useNavigate();
  const { logout } = useAuth(); // Utiliser la fonction logout du contexte

  useEffect(() => {
    // Logique de déconnexion
    sessionStorage.clear();  // Nettoyer sessionStorage

    logout(); // Appeler la fonction logout pour mettre à jour l'état d'authentification dans le contexte

    // Redirection après la déconnexion
    navigate('/acces');  // Rediriger vers la page de connexion
  }, [navigate, logout]);

  return null; // Pas besoin de rendre quoi que ce soit
};

export default Logout;
