import React, { createContext, useState, useContext } from 'react';

const ClientContext = createContext();

export const useClient = () => {
  return useContext(ClientContext);
};

export const ClientProvider = ({ children }) => {
  const [clientData, setClientData] = useState(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);

  const value = {
    clientData,
    loading,
    error,
    setClientData,
    setLoading,
    setError,
  };

  return <ClientContext.Provider value={value}>{children}</ClientContext.Provider>;
};
