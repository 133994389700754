const seoContent = {
  phoneList: (model) => ({
    title: `Réparation du téléphone ${model} - iRepair It`,
    description: `Découvrez notre expertise en réparation du téléphone ${model}. Nous proposons des services rapides et fiables pour tous les modèles ${model}.`,
    ogImage: `${process.env.PUBLIC_URL}/images/${model.toLowerCase().replace(/\s+/g, '-')}.jpg`, // Exemple d'image OG associée au modèle
    ogUrl: `${process.env.REACT_APP_BASE_URL}/phone-list/${encodeURIComponent(model)}`,
  }),
  repairServicesList: {
    title: "Nos Services de Réparation - iRepair It",
    description:
      "Découvrez les services de réparation que nous proposons pour vos smartphones. De la réparation de l'écran aux problèmes de batterie, nous avons l'expertise pour remettre vos appareils en état.",
    ogImage: `${process.env.PUBLIC_URL}/images/repair-services.jpg`,
    ogUrl: `${process.env.REACT_APP_BASE_URL}/repair-services`,
  },
    about: {
      title: "À propos de iRepair It - Réparation de Téléphones",
      description:
        "Chez iRepair It, nous offrons un service rapide, fiable et écologique pour réparer vos téléphones. Nos experts utilisent des pièces de qualité pour garantir une réparation durable.",
    },
    homepage: {
      title: "Accueil - iRepair It - Réparation de Téléphones Apple, Samsung et Google",
      description:
        "Bienvenue sur iRepair It, votre expert en réparation de smartphones. Nous réparons tous types de téléphones, y compris Apple, Samsung et Google, avec des pièces de qualité et un service rapide.",
    },
    contact: {
      title: "Contactez-nous - iRepair It",
      description:
        "Vous avez des questions ou souhaitez en savoir plus sur nos services de réparation ? Contactez-nous dès aujourd'hui via notre formulaire de contact.",
    },
    accessPage: {
      title: "Connexion et Inscription - iRepair It",
      description:
        "Accédez à votre compte ou inscrivez-vous pour profiter des services de réparation de téléphones. Rejoignez iRepair It pour une expérience rapide et fiable.",
    },
    // Ajoutez d'autres pages ici
  };
  
  export default seoContent;
  