import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Form, Button, InputGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useRepair } from '../repair/RepairContext';


const SearchComponent = ({ onSearchSubmit, placeholder }) => {
  const [phones, setPhones] = useState([]);
  const [filteredPhones, setFilteredPhones] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const { updateRepairData } = useRepair();

  useEffect(() => {
    const fetchPhones = async () => {
      try {
        const response = await axios.get(
          'https://shoponlignebackend-production.up.railway.app/phones'
        );
        setPhones(response.data);
      } catch (error) {
        console.error('Erreur lors de la récupération des téléphones:', error);
      }
    };
    fetchPhones();
  }, []);

  const handleSearchInputChange = (e) => {
    const query = e.target.value;
    setSearchTerm(query);

    if (query.trim()) {
      const results = phones.filter((phone) =>
        phone.model.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredPhones(results);
    } else {
      setFilteredPhones([]);
    }
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    if (onSearchSubmit) {
      onSearchSubmit(searchTerm); // Appeler une fonction de callback pour gérer la soumission
    }
  };

  const handlePhoneClick = (phone) => {
    updateRepairData({
      selectedPhone: phone,
      phoneId: phone.id,
    });
    setSearchTerm('');
    setFilteredPhones([]);
  };

  return (
    <div className="search-component">
      <Form onSubmit={handleSearchSubmit}>
        <InputGroup className="search-input-group">
          <Form.Control
            type="text"
            placeholder={placeholder || 'Rechercher...'}
            value={searchTerm}
            onChange={handleSearchInputChange}
            className="search-input"
          />
          <Button type="submit" variant="primary" className="search-button">
            <i className="bi bi-search"></i>
          </Button>
        </InputGroup>
      </Form>

      {searchTerm.trim() && (
        <div className="search-suggestions">
          {filteredPhones.length > 0 ? (
            <ul>
              {filteredPhones.map((phone) => (
                <li key={phone.id}>
                  <Link
                    to={`/phone-detail/${encodeURIComponent(phone.model)}`}
                    onClick={() => handlePhoneClick(phone)}
                  >
                    {phone.model} - {phone.brand}
                  </Link>
                </li>
              ))}
            </ul>
          ) : (
            <div className="no-results">
              Aucun téléphone trouvé pour "{searchTerm}"
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SearchComponent;
