import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logo from './logo.png';
import linksData from './TopMenuData.json'; // Importation des données JSON
import SearchComponent from '../pages/phones/SearchComponent'; // Importation du SearchComponent
import './TopMenu.scss';

const TopMenu = () => {
  const [menuOpen, setMenuOpen] = useState(false); // État pour le menu hamburger
  const navigate = useNavigate();

  const handleSearchSubmit = (query) => {
    if (query.trim()) {
      navigate(`/search?query=${query}`);
    }
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen); // Alterne entre ouvrir et fermer le menu
  };

  return (
    <header className="top-menu">
      <div className="container top-menu-container">
        {/* Logo à gauche */}
        <div className="logo">
          <Link to="/">
            <img src={logo} alt="Logo" />
          </Link>
        </div>

        {/* Barre de recherche au centre (Desktop seulement) */}
        <div className="search-bar d-none d-lg-block">
          <SearchComponent
            placeholder="Rechercher un téléphone..."
            onSearchSubmit={handleSearchSubmit}
          />
        </div>

        {/* Menu Hamburger (visible sur petits écrans) */}
        <div className="menu-toggle d-lg-none" onClick={toggleMenu}>
          <button className="hamburger">
            <i className="bi bi-list"></i>
          </button>
        </div>

        {/* Liens du menu (version desktop et mobile) */}
        <nav className={`top-links ${menuOpen ? 'open' : ''}`}>
          {linksData.links.map((link, index) => (
            <Link key={index} to={link.path} className="nav-link">
              {link.name}
            </Link>
          ))}
        </nav>
      </div>

      {/* Barre de recherche sous le menu hamburger (Mobile seulement) */}
      {menuOpen && (
        <div className="search-bar-mobile d-lg-none">
          <SearchComponent
            placeholder="Rechercher..."
            onSearchSubmit={handleSearchSubmit}
          />
        </div>
      )}
    </header>
  );
};

export default TopMenu;
