import React, { createContext, useState, useContext } from 'react';

// Création du contexte d'authentification
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  // État pour l'utilisateur, le token et la validation de l'authentification
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // Fonction de connexion
  const login = (userData, token) => {
    setUser(userData);      // Stocker l'utilisateur
    setToken(token);        // Stocker le token
    setIsAuthenticated(true); // L'utilisateur est authentifié
  };

  // Fonction de déconnexion
  const logout = () => {
    setUser(null);           // Réinitialiser l'utilisateur
    setToken(null);          // Réinitialiser le token
    setIsAuthenticated(false); // L'utilisateur est déconnecté
  };

  return (
    <AuthContext.Provider value={{ user, token, isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

// Hook pour utiliser le contexte d'authentification dans les composants
export const useAuth = () => useContext(AuthContext);
