import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Import React Router

// Chargez votre clé publique Stripe
const stripePromise = loadStripe('pk_test_51QHpohGhXsR6tWqRGjui96JuLPgRQveG90LKcc6auCBCKOghuGfgBoDBx5cXXaAZNIEgTNDcG1ItzZd6wv2NSK0K000ftQ88Va');

const CheckoutForm = () => {
    const stripe = useStripe();
    const elements = useElements();
    const navigate = useNavigate(); // Initialisation du hook pour redirection
    const [errorMessage, setErrorMessage] = useState('');
    const [isProcessing, setIsProcessing] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsProcessing(true);

        if (!stripe || !elements) {
            setErrorMessage('Stripe n’est pas chargé correctement.');
            return;
        }

        const card = elements.getElement(CardElement);
        if (!card) {
            setErrorMessage('Impossible de récupérer les informations de la carte.');
            return;
        }

        try {
            const clientSecret = sessionStorage.getItem('clientSecret'); // Utilisation de sessionStorage
            if (!clientSecret) {
                setErrorMessage('Aucune clé client trouvée. Veuillez actualiser la page.');
                return;
            }

            const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
                payment_method: {
                    card,
                },
            });

            if (error) {
                setErrorMessage(error.message);
            } else if (paymentIntent.status === 'succeeded') {
                // Redirection vers la page de confirmation
                navigate('/confirmation');
            }
        } catch (err) {
            setErrorMessage('Une erreur est survenue lors du traitement du paiement.');
        } finally {
            setIsProcessing(false);
        }
    };

    return (
        <div className="container mt-5">
            <h2 className="mb-4">Paiement par Carte</h2>
            <form onSubmit={handleSubmit} className="payment-form">
                <div className="form-group">
                    <label htmlFor="card-element">Informations de carte</label>
                    <CardElement id="card-element" className="form-control p-3" />
                </div>
                {errorMessage && (
                    <div className="alert alert-danger mt-3">{errorMessage}</div>
                )}
                <button
                    type="submit"
                    className="btn btn-explore"
                    disabled={!stripe || isProcessing}
                >
                    {isProcessing ? 'Traitement...' : 'Payer'}
                </button>
            </form>
        </div>
    );
};

const PaymentCard = () => {
    const [clientSecret, setClientSecret] = useState('');

    useEffect(() => {
        // Appel au backend pour créer un PaymentIntent et obtenir le clientSecret
        const fetchClientSecret = async () => {
            try {
                const response = await axios.post('https://shoponlignebackend-production.up.railway.app/payment/create-payment-intent', {
                    amount: 5000, // Exemple : Montant en cents (50.00 USD)
                });
                setClientSecret(response.data.clientSecret);
                sessionStorage.setItem('clientSecret', response.data.clientSecret); // Utilisation de sessionStorage
            } catch (err) {
                console.error('Erreur lors de la création du PaymentIntent :', err);
            }
        };
        fetchClientSecret();
    }, []);

    return (
        <div className="payment-page">
            {clientSecret ? (
                <Elements stripe={stripePromise}>
                    <CheckoutForm />
                </Elements>
            ) : (
                <div className="text-center mt-5">
                    <div className="spinner-border text-primary" role="status">
                        <span className="sr-only">Chargement...</span>
                    </div>
                    <p>Chargement des informations de paiement...</p>
                </div>
            )}
        </div>
    );
};

export default PaymentCard;
