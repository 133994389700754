import React, { useState, useEffect } from 'react';
import TopMenu from './TopMenu';
import MainMenu from './MainMenu';
import MobileMenu from './MobileMenu';
import SearchComponent from '../pages/phones/SearchComponent';  // Assure-toi d'importer SearchComponent
import './Header.scss';

const Header = () => {
  const [isMobileOrTablet, setIsMobileOrTablet] = useState(window.innerWidth <= 1024);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');  // Gérer searchTerm au niveau du parent

  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.innerWidth <= 1024;
      setIsMobileOrTablet(isMobile);

      // Si l'écran passe en mode desktop, fermer le menu mobile
      if (!isMobile) {
        setMobileMenuOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  return (
    <header className="header">
      {/* Header mobile/tablette */}
      {isMobileOrTablet ? (
        <>
          <div className="hamburger-container">
            <div
              className={`hamburger ${mobileMenuOpen ? 'open' : ''}`}
              onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
            >
              <span></span>
              <span></span>
              <span></span>
            </div>

            {/* Barre de recherche externe - n'affiche pas si le menu est ouvert */}
            {!mobileMenuOpen && (
              <div className="mobile-search-bar">
                <SearchComponent
                  searchTerm={searchTerm}  // Passer searchTerm au SearchComponent
                  setSearchTerm={setSearchTerm}  // Passer setSearchTerm au SearchComponent
                />
              </div>
            )}
          </div>

          {/* Menu mobile */}
          {mobileMenuOpen && <MobileMenu onClose={() => setMobileMenuOpen(false)} />}
        </>
      ) : (
        // Header desktop
        <>
          <TopMenu />
          <MainMenu />
        </>
      )}
    </header>
  );
};

export default Header;
