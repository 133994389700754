import React, { useState, useEffect } from 'react';
import { Card, Spinner, Alert } from 'react-bootstrap';
import { useAuth } from '../../components/AuthContext'; // Contexte d'authentification
import { useClient } from './ClientContext'; // Contexte du client
import { jwtDecode } from 'jwt-decode';

const ClientInfo = () => {
  const { clientData, setClientData, setLoading, setError } = useClient(); // Récupère clientData et les méthodes du contexte
  const [localError, setLocalError] = useState('');
  const [localLoading, setLocalLoading] = useState(true);
  const { token } = useAuth(); // Récupère le token depuis le contexte

  useEffect(() => {
    const fetchClientData = async () => {
      if (!token) {
        setLocalError('Aucun token trouvé.');
        setLocalLoading(false);
        return;
      }

      try {
        setLocalLoading(true);
        const decodedToken = jwtDecode(token);
        const userId = decodedToken.userId;

        const response = await fetch(
          `https://shoponlignebackend-production.up.railway.app/clients/search?userId=${userId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error(`Erreur API: ${response.statusText}`);
        }

        const responseBody = await response.json();
        setClientData(responseBody); // Sauvegarde les données du client dans le contexte
        setError(''); // Efface l'erreur, s'il y en a
      } catch (error) {
        setLocalError(error.message);
        setError(error.message); // Met à jour l'erreur dans le contexte
        console.error('Erreur lors de la récupération des informations du client:', error);
      } finally {
        setLocalLoading(false);
        setLoading(false); // Met à jour le statut de chargement dans le contexte
      }
    };

    fetchClientData();
  }, [token, setClientData, setLoading, setError]);

  if (localLoading) {
    return <Spinner animation="border" variant="primary" />;
  }

  if (localError || localError !== '') {
    return <Alert variant="danger">{localError}</Alert>;
  }

  return (
    <Card className="mb-4">
      <Card.Header className="recap-header text-start">Informations Personnelles</Card.Header>
      <Card.Body>
        {/* Affichage des données du client */}
        <Card.Text><strong>Nom :</strong> {clientData?.name}</Card.Text>
        <Card.Text><strong>Email :</strong> {clientData?.email}</Card.Text>
        <Card.Text><strong>Téléphone :</strong> {clientData?.phone}</Card.Text>
        <Card.Text><strong>Adresse de Livraison :</strong> {clientData?.deliveryAddress || 'Non spécifiée'}</Card.Text>
        {/* Ajouter d'autres champs si nécessaire */}
      </Card.Body>
    </Card>
  );
};

export default ClientInfo;
