import React, { useEffect, useState } from 'react';
import { Container, Table, Alert } from 'react-bootstrap';
import { useClient } from '../repair/ClientContext'; // Assurez-vous que le chemin est correct
import './RepairHistory.scss';

const RepairHistory = () => {
  const { clientData, loading } = useClient();  // Utilisation du contexte
  const [repairs, setRepairs] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (clientData) {
      if (clientData.repairs && clientData.repairs.length > 0) {
        setRepairs(clientData.repairs);
      } else {
        setErrorMessage('Aucune réparation trouvée.');
      }
    } else {
      setErrorMessage('Aucune donnée client trouvée.');
    }
  }, [clientData]);

  if (loading) {
    return <p>Chargement...</p>;
  }

  if (errorMessage) {
    return <Alert variant="danger">{errorMessage}</Alert>;
  }

  return (
    <Container>
      <h2>Historique des Réparations</h2>
      {repairs.length === 0 ? (
        <p>Aucun historique de réparations à afficher.</p>
      ) : (
        <div className="repair-history-scroll">
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>Date de Début</th>
                <th>Statut</th>
                <th>Services Choisis</th>
                <th>Notes</th>
                <th>Date d'Achèvement Estimée</th>
              </tr>
            </thead>
            <tbody>
              {repairs.map((repair) => (
                <tr key={repair.id}>
                  <td>{new Date(repair.repairStartDate).toLocaleString()}</td>
                  <td>{repair.status}</td>
                  <td>{repair.services}</td>
                  <td>{repair.notes}</td>
                  <td>{new Date(repair.estimatedCompletionDate).toLocaleString()}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}
    </Container>
  );
};

export default RepairHistory;
