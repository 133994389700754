import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Card, Row, Col } from 'react-bootstrap';
import RepairService from '../repair/RepairServicesPage'; // Assurez-vous que le chemin est correct
import { useRepair } from '../repair/RepairContext'; // Importer le hook du contexte
import './PhoneDetailPage.scss'; // Assurez-vous que le fichier SCSS est bien importé

const PhoneDetailPage = () => {
  const { model } = useParams();
  const { repairData } = useRepair(); // Accéder aux données du contexte
  const [phoneDetails, setPhoneDetails] = useState(null);

  useEffect(() => {
    if (repairData && repairData.selectedPhone) {
      const selectedPhone = repairData.selectedPhone;
      if (selectedPhone.model === model) {
        setPhoneDetails(selectedPhone);
      }
    }
  }, [model, repairData]);

  if (!phoneDetails) {
    return (
      <Container className="mt-5">
        <h1>Modèle non trouvé</h1>
        <p>Le modèle {model} n'a pas été trouvé dans les données du téléphone sélectionné.</p>
      </Container>
    );
  }

  return (
    <Container className="mt-3">
      <Row className="g-4">
        {/* Image et Détails du Téléphone */}
        <Col xs={12} md={4} className="d-flex justify-content-center">
          <Card className="shadow-lg w-100 p-3 phone-detail-card">
            <Card.Body>
              <Card.Title className="text-center">{phoneDetails.model}</Card.Title>
            </Card.Body>
            <Card.Img
              src={`${process.env.PUBLIC_URL}/images${phoneDetails.imageUrl}`}
              alt={`Image de ${phoneDetails.model}`}
              className="img-fluid"
            />
            <Card.Body>
              <Card.Text>
                {`Si votre ${phoneDetails.model} est endommagé, nous comprenons que vous souhaitiez le faire réparer rapidement pour profiter pleinement de ses fonctionnalités. Même une petite fissure sur l'écran peut poser des risques, car des éclats de verre peuvent blesser vos doigts ou votre visage.`}
              </Card.Text>
              
            </Card.Body>
          </Card>
        </Col>

        {/* Services de Réparation */}
        <Col xs={12} md={8}>
          <RepairService />
        </Col>
      </Row>
    </Container>
  );
};

export default PhoneDetailPage;
