import React from 'react';
import { Container, Button, ListGroup, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useRepair } from '../repair/RepairContext';
import { useAuth } from '../../components/AuthContext';
import Recapitulatif from './Recapitulatif';
import './ModeEnLigne.scss'; // Le fichier contenant les styles

const ModeEnLigne = () => {
  const navigate = useNavigate();
  const { updateRepairData } = useRepair();
  const { token } = useAuth();

  const handleStartClick = () => {
    updateRepairData('ficheReparationInitiee', true);

    if (!token) {
      navigate('/acces');
    } else {
      navigate('/createrepairpage');
    }
  };

  return (
    <Container className="mode-en-ligne mt-5">
      <h1 className="text-center mb-4">Réparation en ligne</h1>
      <Row>
        <Col xs={12} lg={6} className="mb-4">
          <Recapitulatif />
        </Col>
        
        <Col xs={12} lg={6}>
          <h2 className="text-center mb-4">Processus :</h2>
          <ListGroup className="mb-4">
            <ListGroup.Item className="shadow-lg border-0 text-start hover-effect">
              <strong>1.</strong> Imprimez le formulaire d'envoi que vous trouverez sur notre site.
            </ListGroup.Item>
            <ListGroup.Item className="shadow-lg border-0 text-start hover-effect">
              <strong>2.</strong> Placez votre appareil et le formulaire dans un colis sécurisé et envoyez-le à notre adresse.
            </ListGroup.Item>
            <ListGroup.Item className="shadow-lg border-0 text-start hover-effect">
              <strong>3.</strong> Une fois le colis reçu, nous diagnostiquons le problème et vous informons des réparations nécessaires.
            </ListGroup.Item>
          </ListGroup>
          <div className="text-center">
            <Button variant="success" className="btn-explore" onClick={handleStartClick}>
              Commencer
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ModeEnLigne;
