import React from 'react';
import { ListGroup, Badge, Card } from 'react-bootstrap';
import { useRepair } from './RepairContext'; // Import du contexte
import './Recapitulatif.scss';

const Recapitulatif = () => {
  const { repairData } = useRepair(); // Accès aux données depuis le contexte

  // Préparer les choix à afficher
  const choices = [];
  if (repairData.repairType) choices.push({ label: "Mode de Réparation", value: repairData.repairType });
  if (repairData.selectedPhone) {
    const phoneModel = repairData.selectedPhone.model; // Accéder au modèle du téléphone
    choices.push({ label: "Téléphone Sélectionné", value: phoneModel });
  }

  return (
    <Card className="recap-card">
      <Card.Header className="recap-header text-start">Récapitulatif de vos Choix</Card.Header>
      <ListGroup variant="flush" className="text-start">
        {choices.length > 0 ? (
          choices.map((choice, index) => (
            <ListGroup.Item key={index} className="choice-item">
              <strong>{choice.label}:</strong> <Badge className="badge-custom">{choice.value}</Badge>
            </ListGroup.Item>
          ))
        ) : (
          <ListGroup.Item>Aucun choix enregistré.</ListGroup.Item>
        )}
        {repairData.selectedServices.length > 0 && (
          <>
            <ListGroup.Item className="choice-item">
              <strong>Services Sélectionnés:</strong>
            </ListGroup.Item>
            {repairData.selectedServices.map((service, index) => (
              <ListGroup.Item key={index} className="service-item">
                {index + 1}. {service}
              </ListGroup.Item>
            ))}
          </>
        )}
      </ListGroup>
    </Card>
  );
};

export default Recapitulatif;
