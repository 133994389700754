import React, { useEffect, useMemo } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { useParams, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import './PhonesList.scss';
import phoneOrder from './phoneOrder.json';
import { useRepair } from '../repair/RepairContext';
import seoContent from '../../seo/seoContent';

const PhoneList = () => {
  const { brand } = useParams();
  const [phonesData, setPhonesData] = React.useState([]);
  const { updateRepairData } = useRepair();

  useEffect(() => {
    // Fetch des données des téléphones
    fetch(`https://shoponlignebackend-production.up.railway.app/phones`)
      .then((response) => response.json())
      .then((data) => setPhonesData(data))
      .catch((error) => console.error('Error fetching data:', error));
  }, []);

  // Filtrage et tri des téléphones par marque et modèle
  const filteredPhones = useMemo(() => {
    const filtered = phonesData.filter(
      (phone) => phone.brand.toLowerCase() === brand.toLowerCase()
    );

    return filtered.sort((a, b) => {
      const orderA = phoneOrder[a.model] || Infinity;
      const orderB = phoneOrder[b.model] || Infinity;

      if (orderA !== orderB) {
        return orderA - orderB;
      }
      return a.model.localeCompare(b.model);
    });
  }, [phonesData, brand]);

  // Gestion du clic sur un téléphone
  const handlePhoneClick = (phone) => {
    updateRepairData({
      selectedPhone: phone,
      phoneId: phone.id,
    });
    console.log('Phone selected:', phone);
  };

  // SEO global pour la liste des téléphones d'une marque
  const globalSeo = seoContent.phoneList(brand);

  // Si aucun téléphone n'est trouvé pour la marque
  if (!filteredPhones.length) {
    return (
      <Container className="mt-5 text-center">
        <Helmet>
          <title>{globalSeo.title}</title>
          <meta name="description" content={globalSeo.description} />
          <meta property="og:title" content={globalSeo.title} />
          <meta property="og:description" content={globalSeo.description} />
          <meta property="og:image" content={globalSeo.ogImage} />
          <meta property="og:url" content={globalSeo.ogUrl} />
        </Helmet>
        <Row className="mb-4 section">
          <Col>
            <div className="brand-description">
              <h2>Service sur mesure pour la marque {brand}</h2>
              <p>
                Nous offrons un service sur mesure pour réparer tous types de téléphones.
              </p>
              <p>
                Vous pouvez nous contacter directement via notre{' '}
                <Link to="/contact">page de contact</Link> pour discuter de la réparation
                de votre appareil. Nous serons heureux de vous aider !
              </p>
              <Button variant="primary" as={Link} to="/contact">
                Contactez-nous
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <Container className="mt-5">
      <Helmet>
        <title>{globalSeo.title}</title>
        <meta name="description" content={globalSeo.description} />
        <meta property="og:title" content={globalSeo.title} />
        <meta property="og:description" content={globalSeo.description} />
        <meta property="og:image" content={globalSeo.ogImage} />
        <meta property="og:url" content={globalSeo.ogUrl} />
      </Helmet>
      <Row className="mb-4 section">
        <Col>
          <div className="brand-description">
            <h2>Spécialistes de la Réparation Smartphone {brand}</h2>
            <p>
              Chez <strong>iRepairIt</strong>, nous sommes spécialisés dans la
              réparation de Smartphone {brand}.
            </p>
          </div>
        </Col>
      </Row>
      <Row>
        {filteredPhones.map((phone, index) => (
          <Col md={3} key={index} className="mb-4">
            <Link
              to={`/phone-detail/${encodeURIComponent(phone.model)}`}
              onClick={() => handlePhoneClick(phone)}
            >
              <Card className="phone-card h-100">
                {phone.imageUrl ? (
                  <Card.Img
                    variant="top"
                    src={`${process.env.PUBLIC_URL}/images/${phone.imageUrl}`}
                    alt={phone.model}
                    className="phone-image"
                  />
                ) : (
                  <div className="image-placeholder">Image non disponible</div>
                )}
                <Card.Body>
                  <Card.Title className="phone-title">{phone.model}</Card.Title>
                </Card.Body>
              </Card>
            </Link>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default PhoneList;
