import { useRepair } from './RepairContext'; // Import du hook personnalisé
import { useClient } from './ClientContext'; // Importation du hook useClient

const CreateRepair = ({ onSuccess, onError }) => {
  const { repairData, updateRepairData } = useRepair(); // Inclut clearRepairData
  const { clientData } = useClient(); // Récupérer les données du client depuis le contexte

  const handleCreateRepair = async () => {
    // Vérifiez que les informations nécessaires sont présentes
    if (!repairData.repairType || !clientData?.id) {
      console.log('Données actuelles :', {
        repairType: repairData.repairType,
        clientId: clientData?.id || 'Aucun ID client',
      });
      alert('Veuillez remplir toutes les informations de réparation.');
      return;
    }

    // Préparer les données à envoyer à l'API
    const servicesString = repairData?.selectedServices?.join(', ') || '';
    const phoneModel = repairData.selectedPhone?.model || 'Modèle inconnu';

    console.log('Téléphone :', phoneModel);

    const repairDataToSend = {
      clientId: clientData.id,
      repair: {
        creationDate: new Date().toISOString(),
        status: 'CREATED',
        notes: `Mode: ${repairData.repairMode}, Téléphone: ${phoneModel}`,
        services: servicesString,
      },
    };

    try {
      // Appel API pour créer la réparation
      const response = await fetch(
        'https://shoponlignebackend-production.up.railway.app/repairs',
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(repairDataToSend),
        }
      );

      if (!response.ok) {
        throw new Error('Erreur lors de la création de la réparation');
      }

      const data = await response.json();

      console.log('Réparation créée avec succès, ID:', data.id);
       
      // Met à jour le repairId dans le contexte
      updateRepairData('repairId', data.id);
     
      
      // Appelle le callback de succès si fourni
      if (onSuccess) onSuccess(data);

    } catch (error) {
      console.error('Erreur lors de la création de la réparation:', error);
      alert('Une erreur est survenue lors de la création de la réparation.');

      // Appelle le callback d'erreur si fourni
      if (onError) onError(error);
    }
  };

  return { handleCreateRepair };
};

export default CreateRepair;
