import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import menuItems from './MobileMenuData.json';
import './MobileMenu.scss';

const MobileMenu = ({ onClose }) => {
  const [activeDropdown, setActiveDropdown] = useState(null);

  const toggleDropdown = (index) => {
    setActiveDropdown(activeDropdown === index ? null : index);
  };

  return (
    <nav className="mobile-menu">
      <div className="close-btn" onClick={onClose}>
        ×
      </div>

      <ul className="navbar-nav">
        {menuItems.mobileMenu.map((item, index) => (
          <li key={index} className="nav-item">
            {item.subItems ? (
              <div className={`dropdown ${activeDropdown === index ? 'show' : ''}`}>
                <button
                  className="btn btn-link nav-link dropdown-toggle"
                  onClick={() => toggleDropdown(index)}
                  aria-expanded={activeDropdown === index}
                >
                  {item.label}
                </button>
                <ul className={`dropdown-menu ${activeDropdown === index ? 'show' : ''}`}>
                  {item.subItems.map((subItem, subIndex) => (
                    <li key={subIndex} className="dropdown-item">
                      <Link
                        to={subItem.link}
                        className="dropdown-link"
                        onClick={onClose}
                      >
                        {subItem.label}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            ) : (
              <Link className="nav-link" to={item.link} onClick={onClose}>
                {item.label}
              </Link>
            )}
          </li>
        ))}
      </ul>
    </nav>
  );
};

MobileMenu.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default MobileMenu;
