import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { useClient } from '../repair/ClientContext';  // Assurez-vous que le chemin est correct
import './AccountDetails.scss';

const AccountDetails = () => {
  const { clientData, loading, error } = useClient();  // Utilisation du contexte
  const [isLoading, setIsLoading] = useState(loading);
  const [errorMessage, setErrorMessage] = useState(error);  // Correction ici

  useEffect(() => {
    if (clientData) {
      setIsLoading(false);
    } else {
      setErrorMessage('Aucune donnée utilisateur trouvée.');
      setIsLoading(false);
    }
  }, [clientData]);

  if (isLoading) {
    return <p>Chargement...</p>;
  }

  if (errorMessage) {  // Vérification d'erreur
    return <p>{errorMessage}</p>;
  }

  if (!clientData) {
    return <p>Aucune donnée client disponible.</p>;
  }

  return (
    <Container className="account-details">
      <Row className="mt-4">
        <Col>
          <Card>
            <Card.Header>
              <h4>Détails du Compte</h4>
            </Card.Header>
            <Card.Body>
              <p><strong>Nom :</strong> {clientData.name}</p>
              <p><strong>Email :</strong> {clientData.email}</p>
              <p><strong>Téléphone :</strong> {clientData.phone}</p>
              <p><strong>Adresse :</strong> {clientData.address}, {clientData.city}, {clientData.postalCode}</p>
              <p><strong>Date de naissance :</strong> {new Date(clientData.dateOfBirth).toLocaleDateString()}</p>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default AccountDetails;
