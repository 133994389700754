import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import seoContent from "../../seo/seoContent"; // Importez le fichier contenant les textes
import "../../css/HomePage.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import AOS from "aos";
import "aos/dist/aos.css";

const Homepage = () => {
  const navigate = useNavigate();

  const handleReserveClick = () => {
    navigate("/repair");
  };

  const handleExploreClick = () => {
    navigate("/List-Repair-Services");
  };

  useEffect(() => {
    AOS.init({ duration: 1000, easing: "ease-in-out", once: true });
  }, []);

  const { title, description } = seoContent.homepage;

  return (
    <div className="homepage">
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>

      <section id="cards-section" className="container py-5">
        {/* Section "Réparations Rapides" */}
        <section className="fixes-in-a-flash text-center">
          <h2 className="heading">Réparez votre appareil aujourd'hui !</h2>
          <p className="subheading">Un service rapide et efficace, à portée de main.</p>
          <button className="btn btn-reserve" onClick={handleReserveClick}>
            Réservez en un clic
          </button>
        </section>

        <div className="row">
          {/* Card 1 */}
          <div className="col-md-6 mb-4">
            <div className="card shadow-lg w-100" data-aos="zoom-in">
              <div className="card-body">
                <h2 className="devices-title">
                  Réparation rapide et de qualité pour votre téléphone !
                </h2>
                <p className="devices-description">
                  Spécialistes dans la réparation de smartphones, nous offrons
                  des services rapides, de qualité et à prix attractifs. Faites
                  confiance à notre expertise pour remettre votre téléphone en
                  état !
                </p>
                <button className="btn btn-explore" onClick={handleExploreClick}>
                  Découvrez nos services
                </button>
              </div>
            </div>
          </div>

          {/* Card 2 */}
          <div className="col-md-6 mb-4">
            <div className="card shadow-lg w-100" data-aos="zoom-in">
              <div className="card-body">
                <h2 className="about-title">À Propos de Nous</h2>
                <p className="about-description">
                  Nous nous concentrons sur la réparation de smartphones, en
                  garantissant une prise en charge rapide et un travail de
                  qualité, le tout à des prix compétitifs. Faites confiance à
                  nos experts pour redonner vie à votre appareil.
                </p>
                <button
                  className="btn btn-contact"
                  onClick={() => navigate("/contact")}
                >
                  Contactez-nous
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Homepage;
