import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { useNavigate } from 'react-router-dom';
import PaymentCard from '../components/PaymentCard';
import './PaymentPage.scss'; // Importation des styles CSS

const stripePromise = loadStripe('pk_test_51QHpohGhXsR6tWqRGjui96JuLPgRQveG90LKcc6auCBCKOghuGfgBoDBx5cXXaAZNIEgTNDcG1ItzZd6wv2NSK0K000ftQ88Va');

const PaymentPage = () => {
    const [paymentMethod, setPaymentMethod] = useState('bank_transfer');
    const [email, setEmail] = useState('');
    const [emailConfirmed, setEmailConfirmed] = useState(false);
    const navigate = useNavigate();

    const handleEmailValidation = () => {
        if (email && email.includes('@')) {
            setEmailConfirmed(true);
            alert('Votre adresse e-mail a été confirmée. Vous recevrez les instructions de paiement par e-mail.');
        } else {
            alert('Veuillez entrer une adresse e-mail valide.');
        }
    };

    const handlePaymentSuccess = () => {
        navigate('/confirmation');
    };

    return (
        <div className="container mt-5">
            <h1 className="mb-4 text-center">Paiement</h1>

            <div className="payment-methods mb-4">
                <h4>Choisissez votre méthode de paiement :</h4>
                <div className="btn-group" role="group">
                    <button
                        type="button"
                        className={`btn ${paymentMethod === 'bank_transfer' ? 'active' : ''}`}
                        onClick={() => setPaymentMethod('bank_transfer')}
                    >
                        Virement bancaire
                    </button>
                    <button
                        type="button"
                        className={`btn ${paymentMethod === 'card' ? 'active' : ''}`}
                        onClick={() => setPaymentMethod('card')}
                    >
                        Carte bancaire
                    </button>
                    <button
                        type="button"
                        className={`btn ${paymentMethod === 'paypal' ? 'active' : ''}`}
                        onClick={() => setPaymentMethod('paypal')}
                    >
                        PayPal
                    </button>
                </div>
            </div>

            <div className="payment-details">
                {paymentMethod === 'bank_transfer' && (
                    <div className="alert alert-info">
                        <h5>Instructions pour le virement bancaire :</h5>
                        <p>
                            Merci de confirmer votre adresse e-mail pour recevoir les instructions
                            détaillées pour effectuer le virement bancaire.
                        </p>
                        {!emailConfirmed ? (
                            <div>
                                <input
                                    type="email"
                                    className="form-control my-3"
                                    placeholder="Entrez votre adresse e-mail"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                <button
                                    className="btn"
                                    onClick={handleEmailValidation}
                                >
                                    Valider l'adresse e-mail
                                </button>
                            </div>
                        ) : (
                            <div className="alert alert-success mt-3">
                                Adresse e-mail confirmée. Vous recevrez bientôt les instructions.
                            </div>
                        )}
                    </div>
                )}

                {(paymentMethod === 'card' || paymentMethod === 'paypal') && (
                    <div className="payment-disabled position-relative">
                        <div className="overlay position-absolute w-100 h-100 d-flex align-items-center justify-content-center">
                            <span className="text-white bg-dark p-2 rounded">Arrive bientôt</span>
                        </div>
                        <div className="grayed-content" style={{ opacity: 0.5, pointerEvents: 'none' }}>
                            {paymentMethod === 'card' && (
                                <Elements stripe={stripePromise}>
                                    <PaymentCard onPaymentSuccess={handlePaymentSuccess} />
                                </Elements>
                            )}
                            {paymentMethod === 'paypal' && (
                                <div className="text-center">
                                    <h5>PayPal</h5>
                                    <p>Connectez votre compte PayPal pour un paiement sécurisé.</p>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default PaymentPage;
