import React, { useState } from 'react';
import { Form, Button, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../components/AuthContext'; // Import du contexte Auth
import { useRepair } from '../repair/RepairContext'; // Import du contexte Repair
import './Connexion.scss'; // Import du fichier SCSS

const API_URL = 'https://shoponlignebackend-production.up.railway.app/users';

const Connexion = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  // Utilisation du contexte Auth
  const { login } = useAuth();  
  const { repairData } = useRepair();  // Accès au contexte de réparation

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    try {
      const response = await fetch(`${API_URL}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      if (!response.ok) {
        throw new Error('Erreur lors de la connexion.');
      }

      const { token, user } = await response.json(); // Récupérer le token et les données utilisateur

      if (!token || token.split('.').length !== 3) {
        throw new Error('Token invalide ou manquant.');
      }

      // Appeler la fonction login du contexte pour enregistrer les données utilisateur et le token
      login(user, token);

      // Vérification de la condition pour rediriger vers la page appropriée
      if (repairData.ficheReparationInitiee) {
        navigate('/createrepairpage'); // Rediriger vers la page de création de réparation si la condition est remplie
      } else {
        setTimeout(() => {
          navigate('/account'); // Redirection vers le compte après 2 secondes
        }, 2000);
      }

    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="connexion-container">
      <h2 className="text-center mb-4">Bienvenue !</h2>
      {error && <Alert variant="danger">{error}</Alert>}
      <Form onSubmit={handleSubmit} className="w-100">
        <Form.Group controlId="formEmail">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            placeholder="Entrez votre e-mail"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group controlId="formPassword" className="mt-3">
          <Form.Label>Mot de passe</Form.Label>
          <Form.Control
            type="password"
            placeholder="Entrez votre mot de passe"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </Form.Group>
        <Button type="submit" className="btn btn-explore">
          Connexion
        </Button>
      </Form>
    </div>
  );
};

export default Connexion;
