import React, { useState } from 'react';
import { Container, Form, Button, Alert, Row, Col } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FaUser, FaSignInAlt } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom'; // Importer useNavigate
import './ModeInShop.scss'; // Assurez-vous d'avoir ce fichier pour les styles personnalisés

const ModeInShop = () => {
  const [email, setEmail] = useState('');
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedHour, setSelectedHour] = useState('10');
  const [selectedMinute, setSelectedMinute] = useState('00');
  const [showAlert, setShowAlert] = useState(false);
  const navigate = useNavigate(); // Créer une instance de useNavigate

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowAlert(true);
  };

  return (
    <Container className="mt-5 fade-in">
      <h1 className="text-center mb-4">Réservation en Boutique</h1>
      <p className="text-center mb-4">Choisissez une date et une heure pour recevoir une confirmation de réservation.</p>
      <Row className="mb-3">
        {showAlert && (
          <Col xs={12}>
            <Alert variant="success">Votre réservation a été confirmée !</Alert>
          </Col>
        )}
      </Row>
      <Form onSubmit={handleSubmit} className="bg-light p-4 rounded shadow">
        <Row className="mb-3">
          <Col>
            <Form.Group controlId="formDate">
              <Form.Label>Choisissez le jour</Form.Label>
              <DatePicker
                selected={selectedDate}
                onChange={(date) => setSelectedDate(date)}
                placeholderText="Choisissez une date"
                className="form-control"
                required
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col xs={6}>
            <Form.Group controlId="formTime" className="text-start">
              <Form.Label>Choisissez l'heure</Form.Label>
              <div className="d-flex">
                <Form.Control
                  as="select"
                  value={selectedHour}
                  onChange={(e) => setSelectedHour(e.target.value)}
                  className="me-2"
                  size="sm"
                >
                  {Array.from({ length: 12 }, (_, i) => (
                    <option key={i} value={i + 8}>{i + 8}h</option>
                  ))}
                </Form.Control>
                <Form.Control
                  as="select"
                  value={selectedMinute}
                  onChange={(e) => setSelectedMinute(e.target.value)}
                  size="sm"
                >
                  {['00', '15', '30', '45'].map(minute => (
                    <option key={minute} value={minute}>{minute} min</option>
                  ))}
                </Form.Control>
              </div>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <Form.Group controlId="formEmail">
              <Form.Label>Saisir votre e-mail</Form.Label>
              <Form.Control
                type="email"
                placeholder="Entrez votre e-mail"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <Button 
              variant="primary" 
              className="me-2" 
              onClick={() => navigate('/connexion')} // Rediriger vers la page de connexion
            >
              <FaSignInAlt className="me-1" /> Se connecter
            </Button>
            <Button 
              variant="secondary" 
              onClick={() => navigate('/inscription')} // Rediriger vers la page d'inscription
            >
              <FaUser className="me-1" /> S'inscrire
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default ModeInShop;
