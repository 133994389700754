import React from 'react';
import { Container, Button, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Recapitulatif from './Recapitulatif';
import ClientInfo from './ClientInfo'; // Importation du composant ClientInfo
import CreateRepair from './CreateRepair'; // Importation du composant CreateRepair
import { useRepair } from './RepairContext'; // Import du hook personnalisé
import './CreateRepairPage.scss';

const CreateRepairPage = () => {
  const navigate = useNavigate();
  const { clearRepairData } = useRepair(); // Récupération de la fonction clearRepairData
  const { handleCreateRepair } = CreateRepair({ // Initialisation de CreateRepair
    onSuccess: () => navigate('/payment'), // Naviguer après succès
    onError: (error) => console.error(error),
  });

  // Fonction pour gérer l'annulation
  const handleCancel = () => {
    clearRepairData(); // Vider les données de réparation
    navigate('/'); // Rediriger vers la page d'accueil
  };

  const handlePayment = () => {
    handleCreateRepair(); // Créer la réparation
    clearRepairData();
  };

  return (
    <Container className="mt-5">
      <h1 className="text-center mb-4">Réparation - Récapitulatif</h1>

      {/* Affichage du récapitulatif */}
      <div className="mb-5">
        <Recapitulatif />
      </div>

      {/* Affichage des informations du client */}
      <div className="mt-5">
        <ClientInfo />
      </div>

      {/* Boutons de navigation */}
      <Row className="justify-content-center mt-5">
        <Col xs="auto">
          <Button
            variant="primary"
            onClick={handleCancel}
            className="btn btn-explore"
          >
            Annuler
          </Button>
        </Col>
        <Col xs="auto">
          <Button className='btn btn-explore'
            variant="primary"
            onClick={handlePayment} 
          >
            Paiement
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default CreateRepairPage;
