import React from 'react';
import { Link } from 'react-router-dom';
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa';
import 'bootstrap/dist/css/bootstrap.min.css';

const Footer = () => {
    return (
        <footer className="bg-light text-dark py-4">
            <div className="container">
                <div className="row">
                    {/* Liens rapides */}
                    <div className="col-12 col-md-4 mb-4">
                        <h5 className="text-success">Liens rapides</h5>
                        <ul className="list-unstyled">
                            <li><Link to="/about" className="text-dark text-decoration-none">À propos</Link></li>
                            <li><Link to="/List-Repair-Services" className="text-dark text-decoration-none">Services</Link></li>
                            <li><Link to="/contact" className="text-dark text-decoration-none">Contact</Link></li>
                        </ul>
                    </div>

                    {/* Réseaux sociaux */}
                    <div className="col-12 col-md-4 mb-4">
                        <h5 className="text-success">Suivez-nous</h5>
                        <div>
                            <a href="https://facebook.com" className="text-dark mr-3" target="_blank" rel="noopener noreferrer">
                                <FaFacebookF size={24} />
                            </a>
                            <a href="https://twitter.com" className="text-dark mr-3" target="_blank" rel="noopener noreferrer">
                                <FaTwitter size={24} />
                            </a>
                            <a href="https://instagram.com" className="text-dark mr-3" target="_blank" rel="noopener noreferrer">
                                <FaInstagram size={24} />
                            </a>
                            <a href="https://linkedin.com" className="text-dark" target="_blank" rel="noopener noreferrer">
                                <FaLinkedin size={24} />
                            </a>
                        </div>
                    </div>

                    {/* Copyright */}
                    <div className="col-12 col-md-4 text-center text-md-right">
                        <p>© 2024 Electro Renew | Réparation de smartphones et produits IT - Tous droits réservés</p>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
