import React from 'react';
import { Link } from 'react-router-dom';
import menuItems from './MainMenuData.json'; // Importation du fichier JSON
import './MainMenu.scss';

const MainMenu = () => {
  return (
    <div className="main-menu">
      <div className="container main-menu-container">
        {/* Liens du menu principal */}
        <nav className="nav-links">
          <ul>
            {menuItems.menuItems.map((item, index) => (
              <li key={index}>
                <Link to={item.path}>{item.name}</Link>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default MainMenu;
