import React, { useEffect, useState, useCallback } from 'react';
import { Container, Row, Col, Nav, Alert } from 'react-bootstrap';
import { Outlet, Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../components/AuthContext'; // Importer le contexte Auth
import { jwtDecode } from 'jwt-decode'; // Importation correcte
import { useClient } from '../repair/ClientContext'; // Importer le hook pour le ClientContext
import './AccountPage.scss';

const AccountPage = () => {
  const { token, isAuthenticated, logout } = useAuth(); // Ne plus importer `user`
  const { clientData, setClientData, setLoading } = useClient(); // Accéder aux données et méthodes du contexte
  const navigate = useNavigate();
  const [loadingState, setLoadingState] = useState(true); // Local state pour gestion de loading
  const [error, setError] = useState('');

  // Fonction pour récupérer les données utilisateur, mémorisée avec useCallback
  const fetchUserData = useCallback(async (token) => {
    setLoading(true); // Début du chargement

    const decodedToken = jwtDecode(token);  
    const userId = decodedToken.userId; // Récupérer userId depuis le token

    try {
      const response = await fetch(
        `https://shoponlignebackend-production.up.railway.app/clients/search?userId=${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error('Erreur lors de la récupération des données utilisateur');
      }

      const data = await response.json();
      setLoading(false);
      setClientData(data); // Stocker les données dans le ClientContext
      setLoadingState(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
      setLoadingState(false);
    }
  }, [setClientData, setLoading]); // Ajoutez setLoading dans les dépendances de useCallback

  useEffect(() => {
    if (!token) {
      setError('Aucun token disponible. Veuillez vous connecter.');
      setLoadingState(false);
      navigate('/acces'); // Redirection si pas de token
      return;
    }

    if (isAuthenticated) {
      fetchUserData(token); // Récupérer les données utilisateur
    } else {
      setError('Utilisateur non authentifié.');
      setLoadingState(false);
      navigate('/acces'); // Redirection si utilisateur non authentifié
    }
  }, [token, isAuthenticated, navigate, fetchUserData]); // Ajoutez fetchUserData dans les dépendances

  if (loadingState) {
    return <p>Chargement...</p>;
  }

  if (error) {
    return (
      <Container className="account-page">
        <Row>
          <Col md={12}>
            <Alert variant="danger">{error}</Alert>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <Container fluid className="account-page">
      <Row>
        <Col md={3} className="sidebar">
          <h4 className="text-center">Mon Compte</h4>
          <Nav defaultActiveKey="/account/account-overview" className="flex-column">
            <Nav.Link as={Link} to="/account/account-overview">Aperçu du compte</Nav.Link>
            <Nav.Link as={Link} to="/account/account-details">Détails du compte</Nav.Link>
            <Nav.Link as={Link} to="/account/repair-history">Historique des réparations</Nav.Link>
            <Nav.Link
              as={Link} 
              to="/acces"
              onClick={() => {
                logout(); 
                navigate('/acces'); // Redirection après déconnexion
              }}
            >
              Se déconnecter
            </Nav.Link>
          </Nav>
        </Col>
        <Col md={9} className="content">
          <h2>Bienvenue, {clientData?.name || 'Utilisateur'}!</h2>  {/* Affiche le nom de l'utilisateur ou "Utilisateur" par défaut */}
          <Outlet />
        </Col>
      </Row>
    </Container>
  );
};

export default AccountPage;
